$base-color:#d93c9d;
$base-color-hover:#bd2583;
$auth-page-color1:#a466a6;
$auth-page-color2:#3794f1;
@media screen and (min-width:1200px ){
	.container{
		max-width: 1170px !important;
	}
	
}
.layout_padding {
	padding: 90px 0;
  }
  
  .layout_padding2 {
	padding: 45px 0;
  }
  
  .layout_padding2-top {
	padding-top: 45px;
  }
  
  .layout_padding2-bottom {
	padding-bottom: 45px;
  }
  
  .layout_padding-top {
	padding-top: 90px;
  }
  
  .layout_padding-bottom {
	padding-bottom: 90px;
  }

  .heading_container {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
		-ms-flex-direction: column;
			flex-direction: column;
	-webkit-box-align: start;
		-ms-flex-align: start;
			align-items: flex-start;
  }
  
  .heading_container h2 {
	position: relative;
	font-weight: bold;
  }
  
  .heading_container h2 span {
	color: $base-color;
  }
  
  .heading_container.heading_center {
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
	text-align: center;
  }

  a,
a:hover,
a:focus {
  text-decoration: none !important;
}

a:hover,
a:focus {
  color: initial;
}

.btn,
.btn:focus {
  outline: none !important;
  -webkit-box-shadow: none;
          box-shadow: none;
}


.auth-section {
	background: -webkit-gradient(linear, left top, right top, from($auth-page-color1 ), color-stop($auth-page-color2), to($auth-page-color1 ));
	background: linear-gradient(to right, $auth-page-color1 , $auth-page-color2, $auth-page-color1 );
	min-height: 100vh;

  }

.container-fluid{
	max-width: 1200px;
}
.affix {
    top: 0;
    width: 100%;
    z-index: 9999 !important;
  }

  .affix + .container-fluid {
    padding-top: 70px;
  }
.navbar-collapse{
	flex-grow:0 !important;
}

@media screen and (max-width:768px ){
	.navbar-nav{
		font-size: 0.8rem;
	}
	
}



/* marquee */


.areas {
	width: 100%;
	height: 425px;
	margin: 1em auto;
	overflow: hidden;
	background: white;
	position: relative;
	box-sizing: border-box;
  }
  
  .marquee {
	position: relative;
	box-sizing: border-box;
	animation: marquee 15s linear infinite;
  }
  
  .marquee:hover {
	animation-play-state: paused;
  }
  
  
  /* Make it move! */
  
  @keyframes marquee {
	0% {
	  top: 100%;
	}
	100% {
	  top: 0;
	  transform: translateY(-100%);
	}
  }
  
  
  /* Make it look pretty */
  
  .microsoft .marquee {
	margin: 0;
	padding: 0 1em;
	line-height: 1.5em;
	font: 1em 'Segoe UI', Tahoma, Helvetica, Sans-Serif;
  }
  
  .microsoft:before,
  .microsoft::before,
  .microsoft:after,
  .microsoft::after {
	left: 0;
	z-index: 1;
	content: '';
	position: absolute;
	pointer-events: none;
	width: 100%;
	height: 2em;
	background-image: linear-gradient(180deg, #FFF, rgba(255, 255, 255, 0));
  }
  
  .microsoft:after,
  .microsoft::after {
	bottom: 0;
	transform: rotate(180deg);
  }
  
  .microsoft:before,
  .microsoft::before {
	top: 0;
  }



.notice-section{
	border-radius: 15px;
	box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
	height: 100%;
  }
  .notice-heading{
	font-size: 1.8rem;
	margin: 10px;
	text-align: center;
  }
  .notice-section .marquee{
	margin-top: 20px;
	
  }
  .notice-title{
	display: flex;
	font-size: 1rem;
  }
  .notice-title:hover{
	color: #333;
  }
  .notice-date{
	font-size: 0.85rem;
	font-weight: 800;
	border: 1px solid rgb(119, 119, 119);
	color: #333;
	border-radius: 3px;
  }

  .slider-container{
	max-width: 100% !important;
  }
//   .about_section .img-box  {
// 	display: flex;
//   }
  .about_section .img-box img {
	height: 350px;
	margin: auto;
	border-radius: 10px;
	width: 100%;
  }
  
  .about_section .detail-box p {
	color: #999;
	margin-top: 25px;
  }
  
  .about_section .detail-box a {
	display: inline-block;
	padding: 12px 45px;
	background-color: $base-color;
	color: #ffffff;
	border-radius: 30px;
	margin-top: 15px;
  }
  
  .about_section .detail-box a:hover {
	background-color: $base-color-hover;
  }

.all-button{
	display: inline-block !important;
	// padding: 0.6rem 1.2rem !important; 
	background-color: $base-color !important;
	color: #ffffff !important;
	border-radius: 30px !important;
	margin: 5px !important;
	margin-top: 15px !important;
	border-color: $base-color !important;
}



.all-button:hover{
	background-color: $base-color-hover !important;
}

.all-button:focus{
	color: #ffffff !important;
	box-shadow: none !important;
}

.course_section .card{
	max-width: 300px;
	margin: 20px;
	-webkit-box-shadow: 4px 7px 7px -3px rgba(0,0,0,0.26);
  -moz-box-shadow: 4px 7px 7px -3px rgba(0,0,0,0.26);
  box-shadow: 4px 7px 7px -3px rgba(0,0,0,0.26);
  margin-left: auto;
  margin-right: auto;
  }

  .course_section .card .card-img{
	max-height: 175px;
	
  }


  .bottom_bg {
	background: -webkit-gradient(linear, left top, right top, from($auth-page-color1 ), color-stop($auth-page-color2), to($auth-page-color1 ));
	background: linear-gradient(to right, $auth-page-color1 , $auth-page-color2, $auth-page-color1 );
  }

  .contact_section {
	position: relative;
	color: #ffffff;
  }
  
  .contact_section .heading_container {
	margin-bottom: 25px !important;
  }
  
  .contact_section .form_container input {
	width: 100%;
	border: none;
	height: 50px;
	padding-left: 25px;
	background-color: #ffffff;
	outline: none;
	color: #101010;
	border-radius: 25px;
  }
  
  .contact_section .form_container input::-webkit-input-placeholder {
	color: #c4c3c3;
  }
  
  .contact_section .form_container input:-ms-input-placeholder {
	color: #c4c3c3;
  }
  
  .contact_section .form_container input::-ms-input-placeholder {
	color: #c4c3c3;
  }
  
  .contact_section .form_container input::placeholder {
	color: #c4c3c3;
  }
  
  .contact_section .form_container input.message-box {
	height: 120px;
	border-radius: 15px;
  }
  
  .contact_section .form_container button {
	border: none;
	text-transform: uppercase;
	display: inline-block;
	padding: 12px 55px;
	background-color: #1c1c1c;
	color: #ffffff;
	border-radius: 30px;
  }
  
  .contact_section .form_container button:hover {
	background-color: #030303;
  }
  
  .contact_section .map_container {
	width: 100%;
	height: 345px;
	border-radius: 25px;
	overflow: hidden;
  }
  
  .contact_section .map_container #googleMap {
	height: 100%;
	min-height: 100%;
	width: 100%;
  }
  
  .info_section {
	color: #ffffff;
  }
  
  .info_section hr {
	border: none;
	height: 1px;
	background-color: #ffffff;
	margin: 0;
  }
  
  .info_section .info_box {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
	padding: 35px 0;
  }
  
  .info_section .info_contact {
	-webkit-box-flex: 1;
		-ms-flex: 1;
			flex: 1;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
		-ms-flex-pack: justify;
			justify-content: space-between;
  }
  .info_section .info-address{
	max-width: 230px;
  }
  .info_section .info_contact a {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
	color: #ffffff;
  }
  
  .info_section .info_contact a i {
	margin-right: 5px;
	font-size: 24px;
  }
  
  .info_section .info_contact a:hover {
	color: #1c1c1c;
  }
  
  .info_section .social_box {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-left: 45px;
  }
  
  .info_section .social_box a {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
		-ms-flex-pack: center;
			justify-content: center;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
	width: 45px;
	height: 45px;
	border: 1px solid #ffffff;
	border-radius: 5px;
	color: #ffffff;
	margin-right: 10px;
  }
  
  .info_section .social_box a:hover {
	color: #1c1c1c;
  }
  
  /* footer section*/
  .footer_section {
	position: relative;
	background-color: #363636;
  }
  
  .footer_section .footer_box {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
		-ms-flex-pack: justify;
			justify-content: space-between;
	padding: 25px 0;
  }
  
  .footer_section p {
	color: #929292;
	margin: 0;
  }
  .footer_section a {
	color: #929292;
	margin: 0;
  }
  
  .footer_section p a {
	color: inherit;
  }





.profile-header{
	margin-top: 5rem !important;
}

.profile-row{
	/* margin-top: 10rem; */
	border: 1px solid lightgray;
	border-radius: 5px;
	-webkit-box-shadow: -1px 5px 5px 1px rgba(0,0,0,0.14);
-moz-box-shadow: -1px 5px 5px 1px rgba(0,0,0,0.14);
box-shadow: -1px 5px 5px 1px rgba(0,0,0,0.14);
}
.profile-title{
	font-weight: 600;
}
.profile-data{
	padding-left: 0.7rem;
	font-size: 1.1rem;
}




@media (max-width: 768px) {
  .about_section .detail-box {
    margin-top: 45px;
  }

  .contact_section .map_container {
    margin-top: 45px;
  }

  .info_section .info_box {
    flex-direction: column;
  }

  .info_section .info_contact {
    width: 100%;
  }

  .info_section .social_box {
    margin: 0;
    margin-top: 25px;
  }

  .footer_section .footer_box {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer_section .footer_box p {
    margin-bottom: 15px;
  }

  .logo-section{
	display: none;
	}
}

@media (max-width: 576px) {
  .info_section .info_contact {
    flex-direction: column;
    align-items: center;
  }

  .info_section .info_contact a:not(:nth-last-child(1)) {
    margin-bottom: 15px;
  }
  .info_section .info-address{
	max-width: 100%;
  }
  .contact_section {
	padding-left: 20px;
	padding-right: 20px;
  }
  .all-button{
	padding: 0.4rem 1rem !important; 
	margin: 5px !important;
	margin-top: 15px !important;
	}
	.slider-item{
		height: 300px !important;

	}
	.carousel-img{
		height: 300px !important;
		width: 100%;
	}
	.carousel-img{
		height: 300px;
		width: 100%;
	}
}



@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
  .course-container {
    max-width: 1170px;
  }
}

.carousel-img{
	height: 500px;
	width: 100%;
}

.gallery-img{
	height: 200px;
	width: 90%;
	size: 100%;
	margin: auto;
}
.slick-prev .slick-next{
	background-color: black !important;
}
.progress{
	height: 0.5rem !important;
}

.logo{
	height: 100px;
	width: 120px !important;
}

.slider-item{
	height: 500px;
}
.slider-caption{
	background-color: #1c1c1c;
	opacity: 0.6;
	display: block !important;
}



.paginationItemStyle {
	margin-left: 4px;
	.page-link {
		color: #fff !important;
	}
	&.page-item {
		&.active {
			a {
				background-color: rgb(217, 60, 157) !important;
				border-color: rgb(217, 60, 157) !important;
			}
		}
	}
}

.paginationLinkStyle {
     background-color: #333 !important;
	border: 1px solid #333 !important;
    border-radius: 5px;
    &:hover {
        background-color: rgb(27, 26, 26) !important;
    }
    // &:active {
    //     background-color: #333 !important;
    // }
}
@media (min-width: 576px) {
	.nav-title{
		display: none;
	}
}


//term and conditons
@media (min-width: 1200px) {
	.terms-row{
		max-width: 1000px;
	}
  }

.terms-section li::marker{
	font-weight: 700;
	font-size: 1.1rem;
	margin: 5px;
}